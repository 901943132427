import { render, staticRenderFns } from "./select-audience.vue?vue&type=template&id=41671e50&scoped=true&"
import script from "./select-audience.vue?vue&type=script&lang=ts&"
export * from "./select-audience.vue?vue&type=script&lang=ts&"
import style0 from "./select-audience.vue?vue&type=style&index=0&id=41671e50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41671e50",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VList,VListItem,VListItemGroup,VProgressCircular,VSelect})
