


















































import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: () => [] }) myDaos
  @Prop({ default: () => false }) loadingMyDaos
  @Prop({ default: false }) createPostDialog
  @Ref('selectAudience') selectAudience: any

  defaultAudiences = [
    {
      index: 0,
      value: 'everyone',
      label: 'EveryOne',
      disable: false,
    },
    {
      index: 1,
      value: 'review',
      label: 'Review',
      disable: false,
    },
    {
      index: 2,
      value: 'my-dao',
      label: 'My Dao',
      daos: [],
      disable: false,
    },
  ]

  @Watch('createPostDialog', { immediate: true }) valueChange(value) {
    if (value) {
      this.selectedDao = null
      this.selectedItem = null
      this.isMyDao = false
    }
  }

  get audiences() {
    this.defaultAudiences[2].daos = this.myDaos
    this.defaultAudiences[2].disable = !this.hasMyDaos ? true : false
    return this.defaultAudiences
  }

  selectedDao = null as any
  selectedItem = null as any
  isMyDao = false

  audienceChange(event) {
    if (event === 'my-dao') {
      this.isMyDao = true
      if (!this.selectedDao) this.selectedDao = 0
    } else {
      this.isMyDao = false
      this.selectedDao = null
    }
    this.selectedItem = event
    this.$emit('change', {
      type: event,
      selectedDao: this.isMyDao && this.selectedDao != null ? this.myDaos[this.selectedDao] : undefined,
    })
  }

  blur() {
    this.selectAudience.blur()
  }

  get hasMyDaos() {
    return this.myDaos && this.myDaos.length
  }
}
